:root {
  // --duet-color-primary: #3c4ee0;
  // --duet-color-text: #333;
  // --duet-color-text-active: #fff;
  // --duet-color-placeholder: #666;
  // --duet-color-button: #f5f5f5;
  // --duet-color-surface: #fff;
  // --duet-color-overlay: rgba(0, 0, 0, 0.8);
  // --duet-color-border: #d6d6e7;

  // --duet-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  //   Arial, sans-serif;
  // --duet-font-normal: 400;
  // --duet-font-bold: 600;

  // --duet-radius: 3px;
  // --duet-z-index: 600;
}

// * {
//   box-sizing: border-box;
// }

html,
body {
  margin: 0;
  padding: 0;
}

// body {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
//     Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
// }

header.header {
  display: flex;
  align-items: center;
  z-index: 99;
  padding-top: 56px;
  width: 100%;
  padding: 10px;
  /* background: #222f3f; */
  background: #7cbccb;

}

header.header .is-logo {
  float: left;
  margin-right: 12px;
}

header.header .logo {
  display: block;
}

aside,
.results-wrapper {
  padding: 10px;
}


.ais-Panel {
  margin-top: 20px;
}

.content-wrapper {
  display: flex;
}

.results-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sort-by {
  display: flex;
  align-items: center;
}

.sort-by label {
  margin-right: 5px;
}

footer {
  margin-top: 25px;
}

.results-wrapper {
  width: 100%;
}

.no-results {
  text-align: center;
}

.no-results .query {
  font-style: italic;
}


.date-range-picker {
  display: grid;
  gap: 1rem;
}





.search-filters {
  display: flex;
}

.aisdemo--left-column {
  height: 400px;
  flex: 2 1 0;
}

/* FILTERS
  // ------------------------- */
.aisdemo-filters .aisdemo-filter {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dce0e0;
}

.aisdemo-filters .aisdemo-filter-title {
  font-size: 1em;
  font-weight: normal;
  padding-top: 9px;
  padding-left: 30px;
}

.aisdemo-filters .date {
  font-size: 0.8em;
  padding: 8px 10px;
  border: 1px solid #dce0e0;
}

.dateReset {
  margin: 4px 10px 10px 10px;
  height: 40px;
  width: 100px;
}


/*  */
.results-main-text {
  /* Header 2 */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin: 0;

  /* 90B */

  color: #1A1A1A;
}

.product-type {
  /* Body */

  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* 90B */

  color: #1A1A1A;
}









/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 18%;
  /* 25% from the top */
  width: 100%;
  /* 100% width */
  text-align: center;
  /* Centered text/links */
  margin-top: 30px;
  /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  /* Black fallback color */
  /* background-color: rgba(0,0,0, 0.9); Black w/opacity */
  background-color: rgba(0, 0, 0, 1);
  /* Black w/opacity */
  /* overflow-x: hidden; Disable horizontal scroll */
  overflow-x: scroll;
  transition: 0.8s;

  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    /* Display block instead of inline */
    transition: 0.3s;
    /* Transition effects on hover (color) */
  }
}


/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  color: rgb(244, 241, 241);
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}


.hit {
  background-color: rgba(57, 99, 109, 0.9);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 2px 0px;
  border: 1px solid #4b4b4d;
  padding: 0.4rem;
  transition: 0.2s;


  &:hover {
    // transform: scale(1.04);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 10px 0px;
    // height: 220px;
}
  
  // position:relative;
  // top: 10px;

  /* width:100%; */
  /* max-width: 250px; */
  /* width: 400px; */
  // background-color: white;

  /* margin-top: auto; */
  @media screen and (max-width: 768px) {
    /* max-width: 280px; */
    /* max-width: 360px; */
    /* display: none; */
    /* max-width: 60% */
  }

  @media screen and (min-width: 768px) {
    /* max-width: 340px; */
    /* display: none; */
    /* max-width: 340px; */
  }
}




.cases-advanced-settings {
  background-color: #596f79ec; // Shark
  border-radius: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .top-card {
    margin-left: -1% !important;
    width: 102% !important;
    background-color: rgb(229, 229, 229);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
  }

  .event-types-section {
    background-color: rgba(0, 0, 0, 0.086);
    padding: 0px 6px;
    border-radius: 2px;
  }

  .date-section {
    border-radius: 2px;
    padding: 0px 6px 12px 6px;
    background-color: rgba(0, 0, 0, 0.086);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .sub {
      border-radius: 3px;
      padding: 0.1px 8px;
      border: solid rgba(0, 0, 0, 0.771) 1.4px;
      padding-bottom: 0.1px;
      padding-top: 4px;
    }
  }

}


.cases-wrapper {
  @extend .blue-color;
}

.blue-color {
  // background-color: rgba(50, 118, 255, 0.041);
  background-color: #f6f5f5; // Shark

  // background-color: #1B1F21; // Shark
  // background-color: #242b2f; // Shark
  // background-color: #242b2f; // Shark



}





.officers-info {

  // height: 46px;
  &:hover {
    // height: 100px !important;
    // display:flex;
    // display: none;
  }
}



.result-incident-types {
  margin: 3px;
  max-height: 60px;
  overflow-y: scroll;
  border: solid black;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  padding: 0px 5px;
}






.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
}

// $arrow-color:rgb(6, 6, 26);
$arrow-color: rgb(244, 244, 244);

.arrow {
  transform: scale(0.38) rotate(90deg);

  &.rotated {
    transform: scale(0.38) rotate(180deg);
  }

  position: inline;

  margin-left: -90px;
  margin-top:24px;
  // position: absolute;
  // top: 25px;
  // left:-90px;
  width: 90%;
  height: 10px;
  background-color: $arrow-color;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  animation: arrow 700ms linear infinite;
}

.arrow::after,
.arrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color: $arrow-color;
}

.arrow::after {
  top: -12px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  transform: rotate(-45deg);
}




.page-header {
  font-family: 'Teko', sans-serif;
  font-size: 36pt;
  color: #003a48;
  @media screen and (max-width: 768px) {
    font-size: 38pt;
    margin-bottom: 40px;
  }
}
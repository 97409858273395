
.sec-contentwarning {
    background-color: #e0bf49;
    padding-top: 1rem;
    z-index: 1;
}

.img {
    width: 100%;
    /* height: 585px; */
    height: 540px;
    background: url("./assets/Cover-image.png") center center no-repeat;
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
}

.img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-subtext {
    font-size: 16px;
    line-height: 1.7;
}

.hero-header {
    padding-top: 8px;
    padding-bottom: 22px;
}

@media screen and (min-width: 768px) {
    .hero-header {
        /* text-align: center; */
        padding-left: 2rem;
        padding-right: 1rem;
    }
}

/* @media screen and (max-width: 768px) {  */
@media screen and (max-width: 992px) {                  
    .hero-header {
        text-align: center;
    }
    .hero-subtext {
        padding: 0px 20px;
    }
    .img {
        margin-right: -20px;
    }
}


/* .view-records {
    margin-top: 20px;
} */


.view-records-button {
    font-family: 'Open Sans';
    /* font-size: 16px; */
    font-size: 12pt;

    margin-top: 8   px;
    margin-bottom: 2rem;
    /* font-family: 'Lato' !important; */
    display: inline-block;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    /* font-size: 14px; */
    font-weight: 500;
    line-height: 16px;
    padding: 2px 16px;
    /* height: 38px; */
    height: 42px;
    min-width: 96px;
    /* min-height: 38px; */
    min-height: 42px;
    border: none;
    color: #fff;
    /* background-color: rgb(88, 101, 242); */
    /* background-color: #0C5E96; */
    background-color: #0f66a0;


    transition: background-color .37s ease,color .17s ease;
    /* :hover {
        background-color: rgb(71, 82, 196);
    } */

}

.view-records-button:hover {
    /* background-color: #510c96; */
    background-color: #1a7aa0;

    /* color: black; */
}

@media screen and (max-width: 768px) {
    .view-records-button {
        margin-bottom: 34px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .resources-wrapper {
        text-align: center;
    }
    .resources-text-link {
        padding:0px 20px;
        mask-image: linear-gradient(to bottom,rgba(0,0,0,1) 50%, rgba(0,0,0,.7) 80%, rgba(0,0,0,0.5) 100%) !important;
        -webkit-mask-image: linear-gradient(to bottom,rgba(0,0,0,1) 50%, rgba(0,0,0,.7) 80%, rgba(0,0,0,0.5) 100%) !important;
        margin-left: 0px !important;
    }
}

.resources-icons {
    /* background-color: grey; */
    font-size: 18px;
    margin-right: 6px;
}

.social-icons {
    /* background-color: grey; */
    font-size: 22px;
    margin-right: 6px;
    cursor: pointer;
}

.resources-text {
    /* padding-bottom:'' */
    /* margin-top: -10px; */
    /* display: inline-block; */
}

.resources-text-link {
    mask-image: linear-gradient(to right,rgba(0,0,0,0.8) 80%,rgba(0,0,0,0.2) 100%);
    -webkit-mask-image: linear-gradient(to right,rgba(0,0,0,0.8) 80%,rgba(0,0,0,0.2) 100%);
    font-size: 11pt;
    font-family: 'Open Sans';
    margin-left: 26px;
}


.tooltip-search {
    /* display: none !important; */
    border: solid rgba(186, 183, 183, 0.7) 2px !important;
}

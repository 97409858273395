.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-Results-list,
.ais-ToggleRefinement-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-reset,
.ais-SearchBox-submit,
.ais-VoiceSearch-button {
    padding: 0;
    overflow: visible;
    font: inherit;
    line-height: normal;
    color: inherit;
    background: none;
    border: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-GeoSearch-redo::-moz-focus-inner,
.ais-GeoSearch-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteHits-loadPrevious::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-VoiceSearch-button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-GeoSearch-redo[disabled],
.ais-GeoSearch-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteHits-loadPrevious[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-reset[disabled],
.ais-SearchBox-submit[disabled],
.ais-VoiceSearch-button[disabled] {
    cursor: default
}

.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
    overflow-anchor: none
}

.ais-Breadcrumb-item,
.ais-Breadcrumb-list,
.ais-Pagination-list,
.ais-PoweredBy,
.ais-RangeInput-form,
.ais-RatingMenu-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ais-GeoSearch,
.ais-GeoSearch-map {
    height: 100%
}

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
    margin-left: 1em
}

.ais-PoweredBy-logo {
    display: block;
    height: 1.2em;
    width: auto
}

.ais-RatingMenu-starIcon {
    display: block;
    width: 20px;
    height: 20px
}

.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
    display: none;
    width: 0;
    height: 0
}

.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
    display: none
}

.ais-RangeSlider .rheostat {
    overflow: visible;
    margin-top: 40px;
    margin-bottom: 40px
}

.ais-RangeSlider .rheostat-background {
    height: 6px;
    top: 0;
    width: 100%
}

.ais-RangeSlider .rheostat-handle {
    margin-left: -12px;
    top: -7px
}

.ais-RangeSlider .rheostat-background {
    position: relative;
    background-color: #fff;
    border: 1px solid #aaa
}

.ais-RangeSlider .rheostat-progress {
    position: absolute;
    top: 1px;
    height: 4px;
    background-color: #333
}

.rheostat-handle {
    position: relative;
    z-index: 1;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 50%;
    cursor: -webkit-grab;
    cursor: grab
}

.rheostat-marker {
    margin-left: -1px;
    position: absolute;
    width: 1px;
    height: 5px;
    background-color: #aaa
}

.rheostat-marker--large {
    height: 9px
}

.rheostat-value {
    padding-top: 15px
}

.rheostat-tooltip,
.rheostat-value {
    margin-left: 50%;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.rheostat-tooltip {
    top: -22px
}

[class^=ais-] {
    font-size: 1rem;
    box-sizing: border-box
}

a[class^=ais-] {
    text-decoration: none
}

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-GeoSearch,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-HitsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-Results,
.ais-ResultsPerPage,
.ais-SearchBox,
.ais-SortBy,
.ais-Stats,
.ais-ToggleRefinement {
    color: #3a4570
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
    font-weight: 700
}

.ais-Breadcrumb-separator {
    margin: 0 .3em;
    font-weight: 400
}

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
    color: #0096db;
    transition: color .2s ease-out
}

.ais-Breadcrumb-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link:focus,
.ais-Menu-link:hover,
.ais-Pagination-link:focus,
.ais-Pagination-link:hover,
.ais-RatingMenu-link:focus,
.ais-RatingMenu-link:hover {
    color: #0073a8
}

.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
    padding: .3rem .5rem;
    font-size: .8rem;
    color: #fff;
    background-color: #0096db;
    border-radius: 5px;
    transition: background-color .2s ease-out;
    outline: none
}

.ais-ClearRefinements-button:focus,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset:focus,
.ais-CurrentRefinements-reset:hover,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:focus,
.ais-GeoSearch-reset:hover,
.ais-HierarchicalMenu-showMore:focus,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteHits-loadPrevious:focus,
.ais-InfiniteHits-loadPrevious:hover,
.ais-InfiniteResults-loadMore:focus,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore:focus,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:focus,
.ais-RefinementList-showMore:hover {
    background-color: #0073a8
}

.ais-ClearRefinements-button--disabled,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-HierarchicalMenu-showMore--disabled,
.ais-InfiniteHits-loadMore--disabled,
.ais-InfiniteResults-loadMore--disabled,
.ais-Menu-showMore--disabled,
.ais-RefinementList-showMore--disabled {
    opacity: .6;
    cursor: not-allowed
}

.ais-ClearRefinements-button--disabled:focus,
.ais-ClearRefinements-button--disabled:hover,
.ais-GeoSearch-redo--disabled:focus,
.ais-GeoSearch-redo--disabled:hover,
.ais-GeoSearch-reset--disabled:focus,
.ais-GeoSearch-reset--disabled:hover,
.ais-HierarchicalMenu-showMore--disabled:focus,
.ais-HierarchicalMenu-showMore--disabled:hover,
.ais-InfiniteHits-loadMore--disabled:focus,
.ais-InfiniteHits-loadMore--disabled:hover,
.ais-InfiniteResults-loadMore--disabled:focus,
.ais-InfiniteResults-loadMore--disabled:hover,
.ais-Menu-showMore--disabled:focus,
.ais-Menu-showMore--disabled:hover,
.ais-RefinementList-showMore--disabled:focus,
.ais-RefinementList-showMore--disabled:hover {
    background-color: #0096db
}

.ais-InfiniteHits-loadPrevious--disabled {
    display: none
}

.ais-CurrentRefinements {
    margin-top: -.3rem
}

.ais-CurrentRefinements,
.ais-CurrentRefinements-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.ais-CurrentRefinements-item {
    margin-right: .3rem;
    margin-top: .3rem;
    padding: .3rem .5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #495588;
    border-radius: 5px
}

.ais-CurrentRefinements-category {
    margin-left: .3em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ais-CurrentRefinements-delete {
    margin-left: .3rem
}

.ais-CurrentRefinements-categoryLabel,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-label {
    white-space: nowrap;
    font-size: .8rem;
    color: #fff
}

.ais-CurrentRefinements-reset {
    margin-top: .3rem;
    white-space: nowrap
}

.ais-CurrentRefinements-reset+.ais-CurrentRefinements-list {
    margin-left: .3rem
}

.ais-GeoSearch {
    position: relative
}

.ais-GeoSearch-control {
    position: absolute;
    top: .8rem;
    left: 3.75rem
}

.ais-GeoSearch-label {
    display: block;
    padding: .3rem .5rem;
    font-size: .8rem;
    background-color: #fff;
    border-radius: 5px;
    transition: background-color .2s ease-out;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    outline: none
}

.ais-GeoSearch-input {
    margin: 0 .25rem 0 0
}

.ais-GeoSearch-label,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
    white-space: nowrap
}

.ais-GeoSearch-reset {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.ais-HierarchicalMenu-link,
.ais-Menu-link {
    display: block;
    line-height: 1.5
}

.ais-HierarchicalMenu-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-RatingMenu-list,
.ais-RefinementList-list {
    font-weight: 400;
    line-height: 1.5
}

.ais-HierarchicalMenu-link:after {
    margin-left: .3em;
    content: "";
    width: 10px;
    height: 10px;
    display: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.3 24l-2.8-2.8 9.3-9.2-9.3-9.2L7.3 0l12.2 12z' fill='%233A4570'/%3E%3C/svg%3E");
    background-size: 100% 100%
}

.ais-HierarchicalMenu-item--parent>.ais-HierarchicalMenu-link:after {
    display: inline-block
}

.ais-HierarchicalMenu-item--selected>.ais-HierarchicalMenu-link:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.ais-CurrentRefinements-count,
.ais-RatingMenu-count {
    font-size: .8rem
}

.ais-CurrentRefinements-count:before,
.ais-RatingMenu-count:before {
    content: "("
}

.ais-CurrentRefinements-count:after,
.ais-RatingMenu-count:after {
    content: ")"
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
    padding: .1rem .4rem;
    font-size: .8rem;
    color: #3a4570;
    background-color: #dfe2ee;
    border-radius: 8px
}

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
    margin-top: .5rem
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
    background-color: #ffc168
}

.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Results-list {
    font-family: 'Open Sans';
    margin-top: -1rem;
    /* margin-left: -1rem; */
    /* margin-left: auto; */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap */
}

.ais-Panel-body .ais-Hits-list,
.ais-Panel-body .ais-InfiniteHits-list,
.ais-Panel-body .ais-InfiniteResults-list,
.ais-Panel-body .ais-Results-list {
    margin: .5rem 0 0 -1rem
}

.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
    // SEARCH RESULTS CARD 

    // background-color: rgba(57, 99, 109, 0.9);
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 2px 0px;
    // border: 1px solid #4b4b4d;
    // padding: 0.4rem;

    width: 48%;
    margin: 0.5rem 0.34rem;
    display: inline-block;

    border-radius: 3px;
    transition: 0.2s;
    height: 175px;

    &:hover {
        transform: scale(1.04);
        // box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 10px 0px;
        // height: 220px;
    }
}


@media screen and (max-width: 1100px) {

    .ais-Hits-item,
    .ais-InfiniteHits-item,
    .ais-InfiniteResults-item,
    .ais-Results-item {
        /* width: 90% !important; */
        width: 92% !important;
        margin-left: 4%;
        margin-right: 4%;
        /* margin: 0px 0px; */
    }
}

/* @media screen and (min-width: 768px) {

    .ais-Hits-item,
    .ais-InfiniteHits-item,
    .ais-InfiniteResults-item,
    .ais-Results-item {
        width: 40% !important;
    }
} */

.ais-Panel-body .ais-Hits-item,
.ais-Panel-body .ais-InfiniteHits-item,
.ais-Panel-body .ais-InfiniteResults-item,
.ais-Panel-body .ais-Results-item {
    margin: .5rem 0 .5rem 1rem;
}

.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore {
    margin-top: 1rem
}

.ais-InfiniteHits-loadPrevious {
    margin-bottom: 1rem
}

.ais-HitsPerPage-select,
.ais-MenuSelect-select,
.ais-NumericSelector-select,
.ais-ResultsPerPage-select,
.ais-SortBy-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: .3rem 2rem .3rem .3rem;
    max-width: 100%;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 7.3l2.8-2.8 9.2 9.3 9.2-9.3L24 7.3 12 19.5z' fill='%233A4570'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: 92% 50%;
    border: 1px solid #c4c8d8;
    border-radius: 5px
}

.ais-Panel--collapsible {
    position: relative
}

.ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-footer {
    display: none
}

.ais-Panel--collapsible .ais-Panel-collapseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background: none
}

.ais-Panel-header {
    margin-bottom: .5rem;
    // padding-bottom: .5rem;
    // font-size: .8rem;
    font-size: 1rem;
    // text-decoration: underline;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #c4c8d8
}

.ais-Panel-footer {
    margin-top: .5rem;
    font-size: .8rem
}

.ais-RangeInput-input {
    padding: 0 .2rem;
    width: 5rem;
    height: 1.5rem;
    line-height: 1.5rem
}

.ais-RangeInput-separator {
    margin: 0 .3rem
}

.ais-RangeInput-submit {
    margin-left: .3rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 .5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: .8rem;
    color: #fff;
    background-color: #0096db;
    border: none;
    border-radius: 5px;
    transition: .2s ease-out;
    outline: none
}

.ais-RangeInput-submit:focus,
.ais-RangeInput-submit:hover {
    background-color: #0073a8
}

.ais-RatingMenu-count {
    color: #3a4570
}

.ais-Pagination-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ais-Pagination-item+.ais-Pagination-item {
    margin-left: .3rem
}

.ais-Pagination-link {
    padding: .3rem .6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    transition: background-color .2s ease-out
}

.ais-Pagination-link:focus,
.ais-Pagination-link:hover {
    background-color: #e3e5ec
}

.ais-Pagination-item--disabled .ais-Pagination-link {
    opacity: .6;
    cursor: not-allowed;
    color: #a5abc4
}

.ais-Pagination-item--disabled .ais-Pagination-link:focus,
.ais-Pagination-item--disabled .ais-Pagination-link:hover {
    color: #a5abc4;
    background-color: #fff
}

.ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: #0096db;
    border-color: #0096db
}

.ais-Pagination-item--selected .ais-Pagination-link:focus,
.ais-Pagination-item--selected .ais-Pagination-link:hover {
    color: #fff
}

.ais-PoweredBy-text,
.ais-Stats-text,
.rheostat-tooltip,
.rheostat-value {
    font-size: .8rem
}

.ais-PoweredBy-logo {
    margin-left: .3rem
}

.ais-RangeSlider .rheostat-progress {
    background-color: #495588
}

.ais-RangeSlider .rheostat-background {
    border-color: #878faf;
    box-sizing: border-box
}

.ais-RangeSlider .rheostat-handle {
    border-color: #878faf
}

.ais-RangeSlider .rheostat-marker {
    background-color: #878faf
}

.ais-Panel-body .ais-RangeSlider {
    margin: 2rem 0
}

.ais-RangeSlider-handle {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
    background: #fff;
    border: 1px solid #46aeda;
    border-radius: 50%;
    cursor: pointer
}

.ais-RangeSlider-tooltip {
    position: absolute;
    background: #fff;
    top: -22px;
    font-size: .8em
}

.ais-RangeSlider-value {
    width: 40px;
    position: absolute;
    text-align: center;
    margin-left: -20px;
    padding-top: 15px;
    font-size: .8em
}

.ais-RangeSlider-marker {
    position: absolute;
    background: #ddd;
    margin-left: -1px;
    width: 1px;
    height: 5px
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
    color: #c4c8d8
}

.ais-RatingMenu-item--selected {
    font-weight: 700
}

.ais-RatingMenu-link {
    line-height: 1.5
}

.ais-RatingMenu-link>*+* {
    margin-left: .3rem
}

.ais-RatingMenu-starIcon {
    position: relative;
    top: -1px;
    width: 15px;
    fill: #ffc168
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
    fill: #c4c8d8
}

.ais-HierarchicalMenu-searchBox>*,
.ais-Menu-searchBox>*,
.ais-RefinementList-searchBox>* {
    margin-bottom: .5rem
}

.ais-SearchBox-form {
    display: block;
    position: relative
}

.ais-SearchBox-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: .3rem 1.7rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #c4c8d8;
    border-radius: 5px
}

.ais-SearchBox-input::-webkit-input-placeholder {
    color: #a5aed1
}

.ais-SearchBox-input::-moz-placeholder {
    color: #a5aed1
}

.ais-SearchBox-input:-ms-input-placeholder {
    color: #a5aed1
}

.ais-SearchBox-input:-moz-placeholder {
    color: #a5aed1
}

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset,
.ais-SearchBox-submit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    top: 50%;
    right: .3rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ais-SearchBox-submit {
    left: .3rem
}

.ais-SearchBox-reset {
    right: .3rem
}

.ais-SearchBox-loadingIcon,
.ais-SearchBox-resetIcon,
.ais-SearchBox-submitIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.ais-SearchBox-resetIcon path,
.ais-SearchBox-submitIcon path {
    fill: #495588
}

.ais-SearchBox-submitIcon {
    width: 14px;
    height: 14px
}

.ais-SearchBox-resetIcon {
    width: 12px;
    height: 12px
}

.ais-SearchBox-loadingIcon {
    width: 16px;
    height: 16px
}

.ais-VoiceSearch-button {
    border: none;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 50%;
    color: #3a4570;
    background-color: transparent
}

.ais-VoiceSearch-button svg {
    color: currentColor
}

.ais-VoiceSearch-button:hover {
    cursor: pointer;
    background-color: #a5aed1;
    color: #fff
}

.ais-VoiceSearch-button:disabled {
    color: #a5aed1
}

.ais-VoiceSearch-button:disabled:hover {
    color: #a5aed1;
    cursor: not-allowed;
    background: inherit
}





/* Custom */

.ais-RefinementList-labelText,
.ais-RefinementList-checkbox,
.ais-RefinementList-count {
    cursor: pointer;

}

.ais-RefinementList-label {
    border-radius: 8px;
    margin-bottom: 10px;
    // background-color: rgba(108, 105, 105, 0.547);
    background-color: rgba(108, 105, 105, 0.547);

    border: solid rgba(0, 0, 0, 0.251) 1.4px;
    color: rgb(255, 255, 255);
    padding: 7px 9px 2px 9px;
    width: 100%;
    // box-shadow: rgba(60, 64, 67, 0.118) 0px 1px 2px 0px, rgba(60, 64, 67, 0.057) 0px 1px 3px 1px;
    box-shadow: rgba(60, 64, 67, 0.054) 0px 1px 2px 0px, rgba(43, 46, 48, 0.057) 0px 1px 2px 1px;

}

.ais-RefinementList-checkbox {
    transform: scale(1.4);
    margin-right: 5px;
    margin-left: 4px;
    margin-top: 3px;

}

.ais-RefinementList-labelText {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.039);
    padding-left: 5px;
    padding-right: 6px;
    /* font-family: 'Lato'; */
    font-family: 'Open Sans';
    font-size: 16px;
    // margin-top: -8px;
    margin-top: -4px;
    /* background-color: red; */
    /* background-color:rgba(0, 0, 0, .1); */
    /* min-width:80%; */
}


.ais-RefinementList-count {
    margin-top: -2px;
    /* margin-left: auto; */
}



.ais-RefinementList-label>* {
    display: inline-block !important;
    max-width: 80%;
    vertical-align: top;
}

.ais-Panel-header {
    text-align: center;
    margin-bottom: 10px;
}



@media screen and (max-width: 768px) {
    .filter-panel-options {
        text-align: center;
    }

    .ais-RefinementList-label>* {
        display: block;
        /* max-width: 100%; */
        /* vertical-align: top; */
    }

    .ais-RefinementList-label {
        min-width: none;
    }
}



.ais-Pagination-link {

    // color: white;
    // color: #0d8dfd;
    // text-shadow: 0 0 5px #000;
    //     &:hover {
    //         text-shadow: none;
    //     }
    &.ais-Pagination-link--selected {
        background-color: rgb(12, 122, 159);

        // text-shadow: 0 0 5px rgba(0, 0, 0, 0.643);
        /* background-color:red; */
        // background-color: rgb(92, 116, 144);
        // color: rgb(246, 246, 246);
        // border: solid rgb(8, 111, 171) 2px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }
}
.glossary-styles {


    @import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

    .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

    .lst-kix_list_3-1>li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ul.lst-kix_list_1-0 {
        list-style-type: none
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    .lst-kix_list_3-0>li:before {
        content: "" counter(lst-ctn-kix_list_3-0, upper-latin) ". "
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

    ul.lst-kix_list_5-7 {
        list-style-type: none
    }

    ul.lst-kix_list_5-8 {
        list-style-type: none
    }

    .lst-kix_list_3-1>li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". "
    }

    .lst-kix_list_3-2>li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "
    }

    ul.lst-kix_list_5-5 {
        list-style-type: none
    }

    ul.lst-kix_list_5-6 {
        list-style-type: none
    }

    .lst-kix_list_4-0>li {
        counter-increment: lst-ctn-kix_list_4-0
    }

    ul.lst-kix_list_1-3 {
        list-style-type: none
    }

    .lst-kix_list_3-5>li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". "
    }

    ul.lst-kix_list_5-0 {
        list-style-type: none
    }

    ul.lst-kix_list_1-4 {
        list-style-type: none
    }

    ul.lst-kix_list_1-1 {
        list-style-type: none
    }

    .lst-kix_list_3-4>li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". "
    }

    ul.lst-kix_list_1-2 {
        list-style-type: none
    }

    ul.lst-kix_list_5-3 {
        list-style-type: none
    }

    ul.lst-kix_list_1-7 {
        list-style-type: none
    }

    .lst-kix_list_3-3>li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ul.lst-kix_list_5-4 {
        list-style-type: none
    }

    ul.lst-kix_list_1-8 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    ul.lst-kix_list_5-1 {
        list-style-type: none
    }

    ul.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ul.lst-kix_list_5-2 {
        list-style-type: none
    }

    ul.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

    .lst-kix_list_3-8>li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    .lst-kix_list_3-6>li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

    .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    .lst-kix_list_3-7>li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

    .lst-kix_list_3-2>li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    .lst-kix_list_5-0>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
    }

    .lst-kix_list_4-8>li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
    }

    .lst-kix_list_5-3>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_4-7>li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
    }

    .lst-kix_list_5-2>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_5-1>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_5-7>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_5-6>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_5-8>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    .lst-kix_list_5-4>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_5-5>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-1>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-3>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-0>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-4>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    .lst-kix_list_3-3>li {
        counter-increment: lst-ctn-kix_list_3-3
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

    .lst-kix_list_3-6>li {
        counter-increment: lst-ctn-kix_list_3-6
    }

    .lst-kix_list_6-2>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    .lst-kix_list_6-8>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-5>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_6-7>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

    .lst-kix_list_6-6>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_2-6>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-7>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_3-7>li {
        counter-increment: lst-ctn-kix_list_3-7
    }

    .lst-kix_list_2-4>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-5>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-8>li:before {
        content: "\0025aa  "
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    .lst-kix_list_4-0>li:before {
        content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
    }

    .lst-kix_list_3-8>li {
        counter-increment: lst-ctn-kix_list_3-8
    }

    .lst-kix_list_4-1>li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
    }

    .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
    }

    .lst-kix_list_4-4>li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
    }

    .lst-kix_list_4-3>li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
    }

    .lst-kix_list_4-5>li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
    }

    .lst-kix_list_4-2>li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
    }

    .lst-kix_list_4-6>li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    .lst-kix_list_3-5>li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

    .lst-kix_list_3-4>li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    ul.lst-kix_list_6-6 {
        list-style-type: none
    }

    ul.lst-kix_list_6-7 {
        list-style-type: none
    }

    ul.lst-kix_list_6-4 {
        list-style-type: none
    }

    ul.lst-kix_list_2-8 {
        list-style-type: none
    }

    ul.lst-kix_list_6-5 {
        list-style-type: none
    }

    ul.lst-kix_list_6-8 {
        list-style-type: none
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

    ul.lst-kix_list_2-2 {
        list-style-type: none
    }

    .lst-kix_list_1-0>li:before {
        content: "\0025cf  "
    }

    ul.lst-kix_list_2-3 {
        list-style-type: none
    }

    ul.lst-kix_list_2-0 {
        list-style-type: none
    }

    ul.lst-kix_list_2-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    ul.lst-kix_list_6-2 {
        list-style-type: none
    }

    ul.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

    ul.lst-kix_list_6-3 {
        list-style-type: none
    }

    .lst-kix_list_1-1>li:before {
        content: "o  "
    }

    .lst-kix_list_1-2>li:before {
        content: "\0025aa  "
    }

    ul.lst-kix_list_2-7 {
        list-style-type: none
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    ul.lst-kix_list_6-0 {
        list-style-type: none
    }

    ul.lst-kix_list_2-4 {
        list-style-type: none
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

    ul.lst-kix_list_6-1 {
        list-style-type: none
    }

    ul.lst-kix_list_2-5 {
        list-style-type: none
    }

    .lst-kix_list_1-3>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_1-4>li:before {
        content: "\0025aa  "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

    .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
    }

    .lst-kix_list_1-7>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_1-5>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_1-6>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-0>li:before {
        content: "\0025cf  "
    }

    .lst-kix_list_2-1>li:before {
        content: "o  "
    }

    .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
    }

    .lst-kix_list_1-8>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-2>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-3>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c15 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c9 {
        background-color: #ffffff;
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 14.4pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c1 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c8 {
        background-color: #ffffff;
        padding-top: 14pt;
        padding-bottom: 19.2pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c3 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c18 {
        padding-top: 0pt;
        padding-bottom: 12pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    .c0 {
        padding-top: 0pt;
        padding-bottom: 19.2pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c25 {
        padding-top: 0pt;
        padding-bottom: 12pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c12 {
        color: #06172e;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c19 {
        color: #333333;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c7 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
        @media (max-width: 1250px) {
            padding: 42pt 22pt 42pt 22pt;
          }
          
        
    }

    .c2 {
        font-size: 12pt;
        font-family: "Times New Roman";
        font-weight: 700
    }

    .c22 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c4 {
        font-size: 12pt;
        font-family: "Times New Roman";
        font-weight: 400
    }

    .c5 {
        background-color: #ffff00;
        color: #000000
    }

    .c13 {
        background-color: #00ff00;
        color: #000000
    }

    .c10 {
        background-color: #ffff00;
        color: #333333
    }

    .c6 {
        background-color: #ffffff;
        color: #333333
    }

    .c21 {
        height: 11pt
    }

    .c26 {
        color: #282828
    }

    .c16 {
        background-color: #ffff00
    }

    .c24 {
        color: #202122
    }

    .c23 {
        color: #333333
    }

    .c11 {
        background-color: #ffffff
    }

    .c17 {
        color: #000000
    }

    .c20 {
        font-style: italic
    }

    .c27 {
        margin-left: 36pt
    }

    .c14 {
        background-color: #00ff00
    }

    .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    

}
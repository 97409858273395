/* body {
    font-family: 'Lato';
} */



.header {
    /* font-family: 'Lato'; */
    /* font-family: 'Open Sans'; */

    /* padding: 10px 30px; */
    /* background-color: #7cbccb; */
    background: #FFFFFF;
    /* background: #F7FCFD; */
    /* background-color:silver; */
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.15);
    /* z-index: 2 !important; */
    position: relative;
}

.header-items {
    /* Body Bold */

    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;

    /* Accent Teal */

    color: #295F6D;
}

.sj-logo {
    float: left;
    padding-left: 10px;
    padding-top: 14px;
    /* width: 100%; */
    /* height: auto; */
}

.sj-logo img {
    margin-top: -16px;
    /* width: 100%; */
    width: 5rem;
    /* width: 10%; */
    /* height: auto; */
}

.sj-ipa-logo {
    /* width: 100%; */
    /* height: auto; */
}


.sj-ipa-logo img {
    /* width: 10%; */
    /* width: 20%; */
    /* margin-top: 2px; */
    /* width: 4.3rem; */
    width: 3rem;

    /* width: 10%; */

    /* width: 100%; */
    /* height: auto; */
}

.nav-link {
    /* color: white; */
    font-family: sans-serif; 
}

/* Specific styles for active links */
.link-active {
    // color: black;
    /* background: orange; */
    // text-decoration: underline !important;
    font-weight: bold;
    font-family: sans-serif; 
    color:#293234 !important;

}

.active {
    /* display: none; */
    /* text-decoration: underline; */
}


.preventWrap {
    overflow: hidden;
    white-space: nowrap;
}


.header-icons-container {
    display: none !important;
}



.h1 {
    font-family: 'Teko', sans-serif;
    font-size: 48pt;
    color: #003a48;
}

.h2 {
    font-family: 'Teko', sans-serif;
    font-size: 34pt;
    color: #1e1e1e;
}

.h3 {
    font-family: 'Teko', sans-serif;
    font-size: 28pt;
    color: #1e1e1e;
}

.landing-body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14pt;
    color: #464646;

    @media only screen and (max-width: 992px) {
        padding: 4px 20px;
        // text-align: center;
    }


}


.nav-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 13pt;
    color: #464646;
}



.navbar {
    border-left: solid black 2px;
    border-right: solid black 2px;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .2);

    // box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    // box-shadow: rgba(0, 0, 0, 0.119) 0px 8px 30px -5px;

    border-bottom: solid rgba(167, 167, 167, 0.395) 1px;
    // border-radius: 0 0 10px 3px;
}


.hidden {display:none;}